import React from 'react';
import SecondDescription from './V2';
import MainDescription from './MainDescription';

export const Description = ({ url }: { url: string }) => {
  return (
    <div>
      {url.includes('v2') ? (
        <SecondDescription />
      ) : (
        <MainDescription />
      )}
    </div>
  );
};
