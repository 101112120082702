import React from 'react';
import './index.css'
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export default function MainDescription() {
    const matches = useMediaQuery('(min-width: 768px)');

    return (
        <div className='home-details no-web-display' style={matches ? { padding: '20px' }:{}}>
            <h1 className='heading'><span className='site-title'>Sports<span className='feed'>Feed</span>24</span> – Live Sports Streams Wizard</h1>
            <p className='card-description'>
            Welcome to Sportsfeed 24, the top destination for high-quality, free live sports streams on the internet. Whether you are searching for the best, safest, and hassle-free soccer streaming experience, you have come to the right place. Our platform offers an unmatched streaming experience, featuring live soccer streams and much more.
            </p>
            <p className='card-description'>
            On Sportsfeed 24, you can stream a wide variety of sports. Our platform offers free access to popular events such as the Premier League, LaLiga, Serie A, Ligue 1, Saudi Pro League, NBA, NHL, NFL, MLB, UFC, Boxing, and Formula 1. As a globally recognized site for live sports streaming, we provide a seamless experience for sports fans around the world..
            </p>
            <div>
                <ul>
                    <li>
                        <p>
                            <span className='collection'>Premier League Streams</span>
                        </p>
                        <p className='card-description'>
                        Sportsfeed 24 offers free live streaming of all Premier League matches throughout the current season. Our platform provides comprehensive coverage of every Premier League game, allowing you to enjoy live matches at no cost. Simply visit this page to view all upcoming Premier League matches, along with their scheduled times. Sportsfeed 24 features multiple streaming options, ensuring you can watch every Premier League match with ease.
                        </p> 
                        <p>
                            <span className='collection'><a href='https://www.sportsfeed24.com/leagues/standings'>Premier League Points Table</a></span>
                        </p>
                        <p className='card-description'>
                        The Premier League table provides the latest points and standings for all 20 teams in the league. You can check the updated standings by visiting this link: <a href='https://www.sportsfeed24.com/leagues/standings'>Premier League Table</a>. Additionally, you will find point tables for other popular soccer leagues alongside the Premier League standings.
                        </p>

                        <p>
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>Premier League Upcoming Matches</a></span>
                        </p>
                        <p className='card-description'>
                        The Premier League is heading into the final stretch of the season, with each team having fewer than 10 games left to play. You can find the full Premier League fixture and your favorite teams match schedule on Sportsfeed 24. Visit the <a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>Premier League Fixture</a> page to view the upcoming matches. In addition to Premier League standings, fixtures, and schedules, you can also explore <a href='https://www.sportsfeed24.com/fixtures/league/UEFA Champions League'>UEFA Champions League fixtures</a> and fixtures of other popular leagues and teams <a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>HERE</a>.
                        </p>

                        <p>
                            <span className='collection'>Soccer Live Streams</span>
                        </p>
                        <p className='card-description'>
                        Our live soccer streams deliver HD-quality excitement, allowing you to experience the thrill of matches from the comfort of your home. With fast, high-quality streams, Sportsfeed 24 ensures an unforgettable viewing experience. No matter where you are in the world, you can enjoy live matches from the Premier League, LaLiga, Serie A, Ligue 1, and UEFA Champions League.
                        </p> 

                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'> <a href='https://www.sportsfeed24.com/fixtures/NFL/NFL'>NFL Streams</a></span>
                        </p>
                        <p className='card-description'>
                        Watch live NFL matches on the official Sportsfeed 24 website. We offer live streams of all NFL games, bringing American football fans the excitement of their favorite teams. Do not miss any action—catch all your teams NFL matches right here on our platform.
                        </p>
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/cricket/Cricket'>Cricket Streams</a></span>
                        </p>
                        <p className='card-description'>
                        To all cricket enthusiasts, experience the thrill of every powerful six and wicket fall with our live streams. You can now watch all your favorite matches for free in HD quality. Enjoy live cricket streaming on Sportsfeed 24, bringing the excitement straight to you!
                        </p>
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/UFC/UFC'>UFC Streams</a></span>
                        </p>
                        <p className='card-description'>
                        Are you a UFC fan looking for a hassle-free platform to live stream matches? You have come to the right place! Sportsfeed 24 streams the best UFC matches in top quality, and the best part is, it iss completely free. No need to pay expensive fees to access these events—our website offers the best free content. UFC live streaming is available for every event, so you can catch all the action right here on Sportsfeed 24.
                        </p>
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/NBA/NBA'>NBA Streams</a></span>
                        </p>
                        <p className='card-description'>
                        If you want to experience the excitement of live basketball, you’re in the right place! Stream every NBA game and immerse yourself in the action. For free NBA live streams and to catch all the matches and events, head over to the official Sportsfeed 24 page
                        </p>
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            <span className='collection'>KEY SERVICES - Sports Live Matches of the Today</span>
                        </p>
                        <p className='card-description'>
                        On our home page, we provide todays football fixtures and results from top leagues around the world. You can stay updated with live matches and scores from Premier League, La Liga, Serie A, Bundesliga, Super Lig, and Primeira Liga.

                        We will be adding more leagues in the near future. Our system is automated to keep you updated on daily football fixtures and real-time results from your favorite football leagues.

                        Stay connected for all the latest football action!

                        </p>    
                        <p className='nav-link'>
                            <span className='collection'>
                                Can you watch sports for free and how?
                            </span>
                        </p>
                        <p className='card-description'>
                        Sportsfeed 24 is your go-to destination for live football and sports streams. Watch live matches from all the major leagues and support your favorite football team while enjoying the best free sports streaming service available. You can easily watch live football and sports matches on your phone, computer, or tablet, all without ads and without any cost. Our free live streaming provides an excellent experience for users. If you are looking for a platform that consolidates all the games and fixtures related to your favorite teams, leagues, and sports, Sportsfeed 24 is the perfect choice. 
                        </p>                      
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'>Ad-Free Live Sports Streaming</span>
                        </p>
                        <p className='card-description'>
                        The best thing about Sportsfeed24 is that it provides ad-free live streams of your favorite football matches. You can browse through football and other sports to stay up-to-date with the latest fixtures and matches.

                        We know it is frustrating to have ads interrupt your favorite sports moments. Sportsfeed24 understands this and focuses on providing a smooth user experience. That is why we offer free, ad-free live streaming of sports to our users.</p>     
                    </li>

                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'>Why sportsfeed 24?</span>
                        </p>
                        <div>
                        <p className='card-description'>
                            <strong>Key benefits at sportsfeed24 for users</strong>
                        </p>
                        <ul>
                            <li>
                                <p className='card-description'>
                                    Uninterrupted cross-platform live streaming.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                    Video quality is better and up-to date.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                    Light weight and highly effecient loading resources.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                Multiple player option for every match and additionally, multiple links arealso available.
                                </p>
                            </li>
                        </ul>
                        </div>     
                    </li>
                    
                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'>Frequently asked question about sportsfeed24</span>
                        </p>
                        <p className='card-description'> Is it necessary to make an account to watch sports streams? </p> 
                        <p className='card-description'> Nope, you don’t have to. Just go to the Sportsfeed24 website and start watching any sports without creating an account. Easy! </p> 
                        <p className='card-description'> <strong>Is it safe to use Sportsfeed24?</strong> </p> 
                        <p className='card-description'> No pesky pop-up ads! Just open your browser, go to Sportsfeed24, and watch any sport you want. It’s completely safe for your device. </p> 
                    </li>
                    <li className='nav-item'>
                        <p className='nav-link'>
                            
                            <span className='collection'>In a nut shell</span>
                        </p>
                        <p className='card-description'>
                        In addition to soccer streams, our website offers a wide range of other sports live streams for free and in top quality. We take pride in being one of the best streaming services globally. Happy streaming, and enjoy your sports!
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};