export const AUTH_TOKEN = 'Authorization';

export const LOCAL_STORAGE = {
  ID_TOKEN: 'token',
  USER: 'user',
};

export const HOME_PATH = '/v2';
export const PAGE_TITLES: any = {
  WEBSITE_TITLE: 'Sports Streams | Football streams and Fixtures | Sportsfeed24.to',
  SOCCER: 'Soccer Streams, Sports streams, Reddit Soccer Streams and Fixtures | Sportsfeed24.to',
  BOXING: 'Boxing Streams online and upcoming Boxing Events | Sportsfeed24.to',
  NFL: 'Live NFL Streams and Upcoming NFL Matches | Sportsfeed24.to',
  NBA: 'NBA Streams and Matches | Sportsfeed24.to',
  UFC: 'UFC Live Matches and Streams | Sportsfeed24.to',
  TENNIS: 'TENNIS Matches and Upcoming Fixtures | Sportsfeed24.to',
  RUGBY: 'RUGBY Streams and Fixtures | Sportsfeed24.to',
  MOTOGP: 'MOTOGP Streams and Upcoming Matches | Sportsfeed24.to',
  NHL: 'NHL Live Streams and Fixtures | Sportsfeed24.to',
  MLB: 'MLB Fixture and Live Streams and Upcoming Schedules | Sportsfeed24.to',
  F1: 'F1 Live Streams and Events | Sportsfeed24.to',
  MLS: 'MLS Streams and Upcoming Fixtures | Sportsfeed24.to',
  CRICKET: 'Watch Cricket Live and Checkout Match Schedules | Sportsfeed24.to',
  NASCAR: 'NASCAR online and Match Schedules | Sportsfeed24.to',
  CONTACT: 'Contact and Feedback | Sportsfeed24.to',
  ABOUT: 'Service and Goals | Sportsfeed24.to'
}

export const MESSAGES = {
  EMAIL_SUCCESS: 'Your email has been sent successfully!',
  EMAIL_FAILURE: 'Unable to sent email!',
  ADD_STREAM_SUCCESS: 'Player added successfully!',
  ADD_STREAM_FAILURE: 'Failed to add player!',
  UPDATE_STREAM_SUCCESS: 'Streams updated successfully!',
  UPDATE_STREAM_FAILURE: 'Failed to add sreeams!',
  AUTH_SUCCESS: 'User Authenticated!',
  AUTH_FAILURE: 'Invalid user or password!'
}

export const LOGO_URL = 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/Sportsfeed24-Logo-Official.webp';

export const MENU_CATEGORIES = [
  {
    label: 'Soccer',
    value: 'Soccer'
  },
  {
    label: 'NFL',
    value: 'nfl'
  },
  {
    label: 'F1',
    value: 'F1'
  },
  {
    label: 'NBA',
    value: 'nba'
  },
  {
    label: 'Boxing',
    value: 'Boxing'
  },
  {
    label: 'UFC',
    value: 'ufc'
  },
  {
    label: 'NHL',
    value: 'nhl'
  },
  {
    label: 'MLS',
    value: 'mls'
  },
  {
    label: 'MLB',
    value: 'mlb'
  },
  {
    label: 'Motogp',
    value: 'MOTOGP'
  },
  {
    label: 'Rugby',
    value: 'Rugby'
  }
  // {
  //   label: 'WWE',
  //   value: 'WWE'
  // },
];

export const CATEGORY_LINKS:any = {
  SOCCER: 'Soccer',
  NFL: 'nfl',
  F1: 'F1',
  NBA: 'nba',
  BOXING: 'Boxing',
  UFC: 'ufc',
  NHL: 'nhl',
  MLS: 'mls',
  MLB: 'mlb',
  MOTOGP: 'MOTOGP',
  RUGBY: 'Rugby',
  TENNIS: 'tennis',
  NASCAR: 'NASCAR',
  WWE: 'WWE'
};

export const ADS = {
  MAIN: {
    TYPE: 'main',
    SLOT: '2554300823'
  },
  FIXTURE_LIST_DISPLAY_HORIZONTAL: {
    TYPE: 'FixtureListDisplayHorizontal',
    SLOT: '3824669050'
  },
  PLAYER_DISPLAY_HORIZONTAL: {
    TYPE: 'playerDisplayHorizontal',
    SLOT: '3744778007'
  },
  VERTICLE_MAIN: {
    TYPE: 'vertical',
    SLOT: '2926488530'
  },
  IN_ARTICLE: {
    TYPE: 'in-article',
    SLOT: '6685754230'
  },
  IN_FEED: {
    TYPE: 'in-feed',
    SLOT: '2184823320'
  },
  SQUARE_DISPLAY: {
    TYPE: 'square-display',
    SLOT: '7807264218'
  },
}

export const DEFAULT_LEAGUE_LINK = '/leagues/Premier League/645bec5ba42012fa6eb37334';
export const DEFAULT_TEAM_LINK = '/teams/Liverpool/6455eab25477a7f3d047a4cc';

export const SPORTS_CATEGORIES = [{
  label: 'Soccer',
  value: 'Soccer'
}]

export const STREAMERS = ['freesport'];
export const REDDIT_STREAM = 'redditstream';
export const GREEN_PLAYER_SOURCES = ['Reddit-SoccerStreams', 'Reddit-Streams', 'Reddit-SoccerSt...']

export const OTHER_CATEGORIES = ['tennis', 'Cricket', 'F1', 'Boxing', 'nhl', 'nba', 'mls', 'mlb', 'MOTOGP'];
