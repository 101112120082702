import { Routes, Route, Navigate } from 'react-router-dom';

import HeaderComponent from './components/Header';
import Home from './views/Home';
import Fixture from './views/Fixture';
import Layout, { Content, Footer, Header } from 'antd/es/layout/layout';
import FooterComponent from './components/Footer';
import ContactUsComponent from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutUs from './components/AboutUs';
import Standings from './views/Standings';
import FixtureList from './views/FixtureList';
import { styles } from './styles';
import AddStreamComponent from './components/addStream';
import UpdateStream from './components/updateStream';
import { HOME_PATH } from './utils/constant';

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#000000',
  marginTop: 'auto'
}

const Router = () => {
  return (
    <Layout>
      <Header style={styles.header}>
        <HeaderComponent></HeaderComponent>
      </Header>
      <Content style={{background: '#222121', textAlign: 'center', minHeight: '100vh'}}>
        <Routes>
          <Route
            path='/streams/:categoryName'
            element={
              <Home />
            }
          />
          <Route path={'/'} element={<Navigate to={HOME_PATH} replace />} />
        
          <Route path={HOME_PATH} element={<Home />} />
          <Route
            path='/fixture/:teams'
            element={
              <Fixture />
            }
          />
          <Route
            path='/fixture/:teams/add'
            element={
              <AddStreamComponent />
            }
          />
          <Route
            path='/fixture/:teams/update'
            element={
              <UpdateStream />
            }
          />
          <Route
            path='/leagues/standings'
            element={
              <Standings />
            }
          />
          <Route
            path='/leagues/:leagueName/:id'
            element={
              <FixtureList />
            }
          />
          <Route
            path='/teams/:teamName/:id'
            element={
              <FixtureList />
            }
          />
          <Route
            path='/about-us'
            element={
              <AboutUs />
            }
          />
          <Route
            path='/contact'
            element={
              <ContactUsComponent />
            }
          />
          <Route
            path='/privacy-policy'
            element={
              <PrivacyPolicy />
            }
          />
          <Route path='*' element={<Navigate to={HOME_PATH} />} />
        </Routes>
      </Content>
      <Footer style={footerStyle}>
        <FooterComponent></FooterComponent>
      </Footer>
    </Layout>
  );
};

export default Router;
