import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Card, notification, Input, Modal, Checkbox } from 'antd';
import { addStream, authenticateUser } from '../../apis/fixture.api';
import { MESSAGES } from '../../utils/constant';
import Loader from '../common/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkOutlined, MailOutlined } from '@ant-design/icons';

 interface StreamInterface {
    link: string;
    player2: boolean;
}

interface AuthInterface {
    email: string;
    password: string;
}

export default function AddStreamComponent() {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const navigate = useNavigate();
    const { teams } = useParams();
    
    const handleOk = () => {
    form
        .validateFields()
        .then(values => {
            Authenticate(values);
            setIsModalOpen(false);
        })
        .catch(() => {
            notification.open({
                message: MESSAGES.AUTH_FAILURE
            });
        });
        setIsModalOpen(false);
    };

    const handleCancel = () => {
    setIsModalOpen(false);
    };

    const onFinish = async (values: StreamInterface) => {
        setLoader(true);
        try {
            const userString = localStorage.getItem('user');
            if (userString) {
                const user = JSON.parse(userString);

                let teamA: string | undefined = teams;
                let teamB: string | undefined = '';
                if(teamA && teamA.includes('-vs-')){
                    const teamsArr = teamA.split('-vs-');
                    teamA = teamsArr[0];
                    teamB = teamsArr[1];
                }
                const payload = {
                    player2: values?.player2,
                    teamA: teamA,
                    teamB: teamB,
                    websiteLink: values?.link,
                    ...user
                }
                await addStream(payload);
                notification.open({
                    message: MESSAGES.ADD_STREAM_SUCCESS
                });
            } else {
                notification.open({
                    message: MESSAGES.ADD_STREAM_FAILURE
                });
            }
            navigate(`/fixture/${teams}`)
            setLoader(false); 
        } catch (error) {
            notification.open({
                message: MESSAGES.ADD_STREAM_FAILURE
            });
        }
    };

    const Authenticate = async (values: AuthInterface) => {
        setLoader(true);
        try {
            const response = await authenticateUser(values);
            const isAuthenticated = response?.data.authenticated;
            if(isAuthenticated){
                localStorage.setItem('user', JSON.stringify(values));
            }else{
                notification.open({
                    message: MESSAGES.AUTH_FAILURE
                });
            }
            setAuthenticated(isAuthenticated);
            setLoader(false); 
        } catch (error) {
            notification.open({
                message: MESSAGES.AUTH_FAILURE
            });
        }
    };

    useEffect(() => {
        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString);
            Authenticate(user);
        }
      }, []);

    return (
        <>
            {authenticated && <Row style={{display: 'flex', justifyContent:'center', margin: '20px'}}>
                <Col span={12}>
                    <Card title='Add Stream' color='red'>
                        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
                            {/* Checkbox item */}
                            <Form.Item name="player2" valuePropName="checked" initialValue={false}>
                                <Checkbox>Player 2</Checkbox>
                            </Form.Item>
                            <Form.Item name="link" label="Link">
                            <Input prefix={<LinkOutlined />} />
                            </Form.Item>
                            <Button color='secondary' htmlType="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>}
            {!authenticated && <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false}>
                <Card title='Authenticate User' color='red'>
                    <Form form={form} name="form_item_path" layout="vertical">
                        <Form.Item name="email" label="Email">
                            <Input prefix={<MailOutlined />} />
                        </Form.Item>
                        <Form.Item name="password" label="Link">
                            <Input type='password' prefix={<LinkOutlined />} />
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
            }
            {loader ? <Loader /> : <></>}
        </>
    );
};